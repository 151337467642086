import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

import isElementInViewport from '../utils/viewport-check'

class VideoBanner extends Component {

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  }

  onScroll = () => {
    let el2 = this.refs.h2.querySelector('span')
    if (el2 && isElementInViewport(el2)) {
      el2.classList.add('border-ani-active')
      window.removeEventListener('scroll', this.onScroll)
    }
  }

  render() {

    const { video, intro, poster } = this.props

    return (
      <section id='overview' className='video-banner'>
        <div className='video-banner__bg-video'>
          <video ref='videobg' autoPlay playsInline muted loop poster={poster?.localFile?.childImageSharp?.original?.src}>
            <source src={video} type='video/mp4' />
          </video>
          <span className='tag'>Artist Impression Only</span>
        </div>
        <div className='video-banner__inner' ref='h2'>
          <Fade bottom distance='50px'>
            <h2 dangerouslySetInnerHTML={{ __html: intro }} />
          </Fade>
        </div>
      </section>
    )
  }
}

export default VideoBanner
