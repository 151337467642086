import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

import isElementInViewport from '../utils/viewport-check'

import Background from '../assets/images/OhernsMap_Background-03.svg'
import Base from '../assets/images/OhernsMap_Base-03.svg'

import Proximity from '../assets/images/OhernsMap_Proximity-03.svg'
import RingRoad from '../assets/images/OhernsMap_MetroRingRoad-03.svg'
import MajorRoads from '../assets/images/OhernsMap_MajorRoads-03.svg'
import FutureRoads from '../assets/images/OhernsMap_FutureRoads-03.svg'
import MajorPorts from '../assets/images/OhernsMap_MajorPorts-03.svg'
import FreightRail from '../assets/images/OhernsMap_FreightRail-03.svg'
import FreightRoads from '../assets/images/OhernsMap_FreightRoads-03.svg'

import KeyMajorRoads from '../assets/images/key-major-roads.svg'
import KeyFutureRoads from '../assets/images/key-future-roads.svg'
import KeyPorts from '../assets/images/key-ports.svg'
import KeyRingRoad from '../assets/images/key-ring-road.svg'
import KeyProximity from '../assets/images/key-proximity.svg'
import KeyFreightRoads from '../assets/images/key-freight-roads.svg'
import KeyFreight from '../assets/images/key-freight.svg'

class Map extends Component {

  state = {
    features: new Array(7).fill(true),
  }

  showFeature(i) {
    let newFeatures = new Array(7).fill(false)
    this.setState({ features: newFeatures })
    this.setState({ features: {[i]: true} })
  }
  showAll() {
    let newFeatures = new Array(7).fill(true)
    this.setState({ features: newFeatures })
  }
  
  componentDidMount() {
    window.addEventListener('scroll', this.onScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  }

  onScroll = () => {
    let el = this.refs.cont.querySelector('h2 span')
    if (el && isElementInViewport(el)) {
      el.classList.add('border-ani-active')
      window.removeEventListener('scroll', this.onScroll)
    }
  }

  render() {
    const { subtitle, title } = this.props
    let { features } = this.state
    return (
      <section className='map' id='location' ref='cont'>
        <Fade bottom distance='50px'>
          <div className='map__inner'>
            <h4 className=''>{subtitle}</h4>
            <div className='map__wrapper'>
              <img className='map__img' src={Background} alt="O'Herns Logistics Park" />
              { features[0] && <img src={MajorRoads} alt="O'Herns Logistics Park" /> }
              { features[2] && <img src={FreightRail} alt="O'Herns Logistics Park" /> }
              { features[3] && <img src={RingRoad} alt="O'Herns Logistics Park" /> }
              { features[4] && <img src={FutureRoads} alt="O'Herns Logistics Park" /> }
              { features[1] && <img src={FreightRoads} alt="O'Herns Logistics Park" /> }
              { features[6] && <img src={Proximity} alt="O'Herns Logistics Park" /> }
              { features[5] && <img src={MajorPorts} alt="O'Herns Logistics Park" /> }
              <img className='map__base' src={Base} alt="O'Herns Logistics Park" />
              <ul className='map__key'>
                <li onMouseEnter={() => this.showFeature(0)} onMouseLeave={() => this.showAll()}>
                  <img src={KeyMajorRoads} alt='Key Major Roads' />
                  <span>Major Roads</span>
                </li>
                <li onMouseEnter={() => this.showFeature(1)} onMouseLeave={() => this.showAll()}>
                  <img src={KeyFreightRoads} alt='Key Principle Freight Roads' />
                  <span>Principle Freight Roads</span>
                </li>
                <li onMouseEnter={() => this.showFeature(2)} onMouseLeave={() => this.showAll()}>
                  <img src={KeyFreight} alt='Key Freight Rail' />
                  <span>Principle Freight Rail</span>
                </li>
                <li onMouseEnter={() => this.showFeature(3)} onMouseLeave={() => this.showAll()}>
                  <img src={KeyRingRoad} alt='Key Ring Roads' />
                  <span>Metropolitan Ring Road</span>
                </li>
                <li onMouseEnter={() => this.showFeature(4)} onMouseLeave={() => this.showAll()}>
                  <img src={KeyFutureRoads} alt='Key Future Roads' />
                  <span>Future Major Roads</span>
                </li>
                <li onMouseEnter={() => this.showFeature(5)} onMouseLeave={() => this.showAll()}>
                  <img src={KeyPorts} alt='Key Major Ports' />
                  <span>Major Ports</span>
                </li>
                <li onMouseEnter={() => this.showFeature(6)} onMouseLeave={() => this.showAll()}>
                  <img src={KeyProximity} alt='Key Proximity' />
                  <span>Proximity</span>
                </li>
              </ul>
            </div>
            <h2 className='map__title' dangerouslySetInnerHTML={{ __html: title }} />
          </div>
        </Fade>
      </section>
    )
  }
}

export default Map
