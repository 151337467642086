import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class BasicContent extends Component {

  render() {
    const { title, h1, content, hashId } = this.props
    return (
      <section className='basic-content' id={hashId}>
        <Fade bottom distance='50px'>
          <div className='basic-content__inner'>
            <div className='basic-content__title'>
              { h1 && <h1>{title}</h1> }
              { !h1 && <h2>{title}</h2> }
            </div>
            <div className='basic-content__content'>
              { content && <div dangerouslySetInnerHTML={{ __html: content }} /> }
            </div>
          </div>
        </Fade>
      </section>
    )
  }
}

export default BasicContent