import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class Stage extends Component {

  render() {
    const { subtitle, image, content } = this.props
    return (
      <section className='image'>
        <Fade bottom distance='50px'>
          <div className='stage__inner'>
            <h4 className=''>{subtitle}</h4>
            { image && <img className='stage__img' src={image?.localFile?.childImageSharp.original.src} alt="O'Herns Logistics Park" /> }
            <div className='stage__content' dangerouslySetInnerHTML={{ __html: content }} />
          </div>
        </Fade>
      </section>
    )
  }
}

export default Stage