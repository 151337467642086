import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class ImageTitleContent extends Component {

  render() {
    const { image, subtitle, title, content, reverse, imageSize } = this.props
    return (
      <section className={`image-title-content ${reverse ? 'reverse' : ''} ${imageSize}`}>
        <Fade bottom distance='50px'>
          <div className='image-title-content__inner'>
            <div className='image-title-content__image'>
              { image && <img src={image?.localFile?.childImageSharp.original.src} alt={title} /> }
            </div>
            <div className='image-title-content__content'>
              { subtitle && <h4 className='image-title-content__subtitle subtitle'>{subtitle}</h4> }
              { title && <h3 className='image-title-content__title'>{title}</h3> }
              { content && <div className='image-title-content__content' dangerouslySetInnerHTML={{ __html: content }} /> }
            </div>
          </div>
        </Fade>
      </section>
    )
  }
}

export default ImageTitleContent