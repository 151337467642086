import React, { Component } from 'react'
import Link from '../utils/link'

import Fade from 'react-reveal/Fade'

class Team extends Component {

  render() {
    const { subtitle, title, team } = this.props
    return (
      <section id="contact" className='team'>
        <Fade bottom distance='50px'>
          <div className='team__inner'>
            <h4 className=''>{subtitle}</h4>
            <h2 className='image__title' dangerouslySetInnerHTML={{ __html: title }} />
            { team &&
              <div className='team__items'>
                { team.map((el, i) => (
                  <div className='team__item' key={i}>
                    <img src={el.profileImage?.localFile?.childImageSharp.original.src} alt={el.name} />
                    <h3>{el.name}</h3>
                    <p>{el.position}</p>
                    <ul>
                      { el.phone.link && <li><span>T</span> <Link to={el.phone.link}>{el.phone.text}</Link></li> }
                      { el.mobile.link && <li><span>M</span> <Link to={el.mobile.link}>{el.mobile.text}</Link></li> }
                      { el.email.link && <li><span>E</span> <Link to={el.email.link}>{el.email.text}</Link></li> }
                    </ul>
                  </div>
                )) }
              </div>
            }
          </div>
        </Fade>
      </section>
    )
  }
}

export default Team