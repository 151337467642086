import React from 'react'

import VideoBanner from '../components/video-banner'
import Video from '../components/video'
import BasicContent from '../components/basic-content'
import ImageTitleContent from '../components/image-title-content'
import Statistics from '../components/statistics'
import Image from '../components/image'
import Stage from '../components/stage'
import Team from '../components/team'
import Map from '../components/map'

const renderBlock = (param, el, i, postType, func=null) => {
  param = param.replace(postType, '')
  let block = {
  	'_Acf_Components_VideoBanner' : (el, i) => <VideoBanner key={i} {...el} />,
    '_Acf_Components_Video' : (el, i) => <Video key={i} {...el} />,
    '_Acf_Components_ImageTitleContent' : (el, i) => <ImageTitleContent key={i} {...el} />,
    '_Acf_Components_BasicContent' : (el, i) => <BasicContent key={i} {...el} />,
    '_Acf_Components_Statistics' : (el, i) => <Statistics key={i} id={i} {...el} />,
    '_Acf_Components_Image' : (el, i) => <Image key={i} {...el} />,
    '_Acf_Components_Stage' : (el, i) => <Stage key={i} {...el} />,
    '_Acf_Components_Team' : (el, i) => <Team key={i} {...el} />,
    '_Acf_Components_Map' : (el, i) => <Map key={i} {...el} />,
  }[param]

  if (!block) return null

  return block(el, i)
}

export default renderBlock