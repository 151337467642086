import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'
import isElementInViewport from '../utils/viewport-check'

class Image extends Component {

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  }

  onScroll = () => {
    let el = this.refs.cont.querySelector('h2 span')
    if (el && isElementInViewport(el)) {
      el.classList.add('border-ani-active')
      window.removeEventListener('scroll', this.onScroll)
    }
  }

  render() {
    const { subtitle, image, title } = this.props
    return (
      <section id="sustainability" className='image' ref='cont'>
        <Fade bottom distance='50px'>
          <div className='image__inner'>
            { subtitle && <h4 className=''>{subtitle}</h4> }
            { image && <img className='image__img' src={image?.localFile?.childImageSharp.original.src} alt="O'Herns Logistics Park" /> }
            { title && <h2 className='image__title' dangerouslySetInnerHTML={{ __html: title }} /> }
          </div>
        </Fade>
      </section>
    )
  }
}

export default Image
