import React, { Component } from 'react'
import { graphql } from 'gatsby'
import he from 'he'

import Seo from '../components/seo'

import RenderBlock from '../utils/render-block'

class PageTemplate extends Component {

  render() {
    let page = this.props.data.wpPage

    return (
      <>
        <Seo title={he.decode(page.seo.title)} bodyClass={page.slug} description={page.seo.metaDesc} />
        { page.acf.components && page.acf.components.map((el, i) => RenderBlock(el.fieldGroupName, el, i, 'page'))  }
      </>
    )
  }
}

export const pageQuery = graphql`
  query ($id: String!) {
    wpPage(id: {eq: $id}) {
      title
      slug
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          localFile {
            childImageSharp {
              original {
                src
                width
                height
              }
            }
          }
        }
      }
      acf {
        components {
          ... on WpPage_Acf_Components_VideoBanner {
            fieldGroupName
            video
            intro
            poster {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
          }
          ... on WpPage_Acf_Components_Video {
            fieldGroupName
            video
            poster {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
          }
          ... on WpPage_Acf_Components_ImageTitleContent {
            fieldGroupName
            image {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
            subtitle
            title
            content
            reverse
            imageSize
          }
          ... on WpPage_Acf_Components_BasicContent {
            fieldGroupName
            title
            h1
            content
            hashId
          }
          ... on WpPage_Acf_Components_Statistics {
            fieldGroupName
            title
            stats {
              icon
              stat
            }
          }
          ... on WpPage_Acf_Components_Image {
            fieldGroupName
            subtitle
            image {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
            title
          }
          ... on WpPage_Acf_Components_Map {
            fieldGroupName
            subtitle
            title
          }
          ... on WpPage_Acf_Components_Stage {
            fieldGroupName
            subtitle
            image {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
            content
          }
          ... on WpPage_Acf_Components_Team {
            fieldGroupName
            subtitle
            title
            team {
              profileImage {
                localFile {
                  childImageSharp {
                    original {
                      src
                    }
                  }
                }
              }
              name
              position
              phone {
                text
                link
              }
              mobile {
                text
                link
              }
              email {
                text
                link
              }
            }
          }
        }
      }
    }
  }
`

export default PageTemplate
