import React, { Component } from 'react'

import ReactVivus from 'react-vivus'

import isElementInViewport from '../utils/viewport-check'

import Stats1 from '../assets/images/stats-1.svg'
import Stats2 from '../assets/images/stats-2.svg'
import Stats3 from '../assets/images/stats-3.svg'
import Stats4 from '../assets/images/stats-4.svg'
import Stats5 from '../assets/images/stats-5.svg'
import Stats6 from '../assets/images/stats-6.svg'

import Boat from '../assets/images/Boat.svg'
import Infrastructure from '../assets/images/Infrastructure.svg'
import Train from '../assets/images/Train.svg'
import HourAccess from '../assets/images/HourAccess.svg'
import Airport from '../assets/images/Airport.svg'
import ConservationArea from '../assets/images/ConservationArea.svg'
import DiamondInterchange from '../assets/images/DiamondInterchange.svg'
import FlexibleLand from '../assets/images/FlexibleLand.svg'
import GasAvailable from '../assets/images/GasAvailable.svg'
import HeavyVehicle from '../assets/images/HeavyVehicle.svg'
import Masterplan from '../assets/images/Masterplan.svg'
import MelbourneCBD from '../assets/images/MelbourneCBD.svg'
import PurposeBuilt from '../assets/images/PurposeBuilt.svg'
import Shopping from '../assets/images/Shopping.svg'

const statsLines = [
  Stats1,
  Stats2,
  Stats3,
  Stats4,
  Stats5,
  Stats6
]

const statsIcons = {
  'boat': Boat,
  'infrastructure': Infrastructure,
  'train': Train,
  'hour_access': HourAccess,
  'airport': Airport,
  'conservation_area': ConservationArea,
  'diamond_interchange': DiamondInterchange,
  'flexible_land': FlexibleLand,
  'gas_available': GasAvailable,
  'heavy_vehicle': HeavyVehicle,
  'masterplan': Masterplan,
  'melbourne_cbd': MelbourneCBD,
  'purpose_built': PurposeBuilt,
  'shopping': Shopping
}

class Statistics extends Component {

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  }

  onScroll = () => {
    let el = this.refs.h2.querySelector('span')
    if (isElementInViewport(el)) {
      el.classList.add('border-ani-active')
      window.removeEventListener('scroll', this.onScroll)
    }
  }

  render() {
    const { title, stats, id } = this.props

    return (
      <section className='stats'>
        <div className='stats__inner'>
        	{ stats &&
	          <div className='stats__wrapper'>
	          	{ stats.map((el, i) => (
	          		<div className={`stats__item stats__item--${i+1} stat-${id}-${i+1}`} key={i}>
	          			<ReactVivus
	          				id={`stat-${id}-${i+1}`}
								    option={{
								      file: statsLines[i],
								      type: 'delayed',
								      animTimingFunction: 'EASE',
								      duration: 200
								    }}
								  />
								  <div className='stats__icon'>
									  <ReactVivus
		          				id={`stat-icon-${id}-${i+1}`}
									    option={{
									      file: statsIcons[el.icon],
									      type: 'delayed',
									      animTimingFunction: 'EASE',
									      duration: 200
									    }}
									  />
									</div>
								  <span dangerouslySetInnerHTML={{ __html: el.stat }} />
		          	</div>
	          	)) }
	          	<div className='stats__center'>
	          		<h2 ref='h2' dangerouslySetInnerHTML={{ __html: title }} />
	          	</div>
	          </div>
	        }
        </div>
      </section>
    )
  }
}

export default Statistics
